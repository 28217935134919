module.exports = {
  comm: {
    home: 'الرئيسية', // 新增
    pricing: 'التسعير', // 新增
    blog: 'مدونة', // 新增
    language: 'لغة:',
    select_language: 'اختر اللغة',
    txt2voice: 'نص إلى صوت',
    voice2txt: 'صوت إلى نص',
    voiceclone: 'استنساخ صوت',
    video2txt: 'فيديو إلى نص',
    footer_help: 'هل تحتاج إلى مساعدة؟ راسلنا عبر البريد الإلكتروني:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'تسجيل الدخول',
    login_desc: 'قم بتسجيل الدخول للوصول إلى حساب مولد صوت TikTok الخاص بك',
    logout: 'تسجيل الخروج',
  },
  txt2voice: {
    title: 'مولد صوت TikTok: توليد أصوات AI TikTok مضحكة',
    description: 'توليد وتحميل أصوات AI TikTok مضحكة مجاناً، مثل صوت جيسي، صوت C3PO، صوت غوستفيس...',
    keywords: 'مولد صوت TikTok, صوت AI TikTok, مولد صوت Tik Tok',
    main_title_p01: 'مولد صوت TikTok',
    main_title_p02: 'توليد أصوات TikTok مضحكة: صوت جيسي، صوت C3PO، صوت غوستفيس',
    main_textarea_holder: 'اكتب أو الصق النص هنا',
    main_genvoice: 'توليد',
    main_generating: 'جارٍ التوليد',
    main_input_empty: 'يرجى كتابة أو لصق النص',
    daily_usage_limit_msg: '1) لقد وصلت إلى الحد الأقصى لاستخدام اليوم. يرجى العودة غدًا. 2) إذا كنت بحاجة ماسة إلى مزيد من الاستخدام، يرجى الاتصال بنا عبر البريد الإلكتروني.',
    text_max_prompt1: 'يرجى تحديد عدد الكلمات إلى',
    text_max_prompt2: 'كلمات أو أقل!',
    popup_nologin_title: 'قم بترقية خطة اشتراكك',
    popup_nologin_desc: 'قم بإلغاء قفل المزيد من وقت إنشاء الصوت عن طريق الترقية إلى اشتراك مميز.',
    popup_nologin_btntext: 'استكشف خطط الاشتراك',
    popup_nosub_title: 'قم بترقية خطة اشتراكك',
    popup_nosub_desc: 'قم بإلغاء قفل المزيد من وقت إنشاء الصوت عن طريق الترقية إلى اشتراك مميز.',
    popup_nosub_btntext: 'استكشف خطط الاشتراك',
    popup_sublimit_title: 'تم استنفاد الاشتراك، يرجى الترقية',
    popup_sublimit_desc: 'قم بتحسين تجربتك مع ميزاتنا المتميزة والوصول غير المحدود.',
    popup_sublimit_btntext: 'انتقل للشراء المزيد',
  },
  setting: {
    setting: 'الإعدادات',
    setting_title: 'إدارة حسابك واستخدامك واشتراكك هنا.',
    basic_info: 'المعلومات الأساسية', // 新增，放在 setting_title 下方
    user_name: 'الاسم',
    user_email: 'البريد الإلكتروني',
    user_account: 'الحساب',
    user_subscript: 'الخطة الحالية',
    user_usage: 'الاستخدام',
    manage_subscript: 'إدارة الاشتراك',
    use_limit_promote: "لقد استخدمت 5000 حرف من حد 10000 حرف الشهري.",
    not_logged_in: 'لم تقم بتسجيل الدخول', // 新增
    go_to_login: 'انتقل لتسجيل الدخول >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "مولد صوت TikTok",
    subtitle: "قم بتسجيل الدخول للوصول إلى حساب مولد صوت TikTok الخاص بك",
    alreadyLoggedIn: "لقد قمت بتسجيل الدخول بالفعل",
    goToHome: "انتقل إلى الصفحة الرئيسية"
  },
  faq: {
    quest1: 'مولد صوت TikTok - أفضل أداة لصوت AI TikTok',
    answer1_1: 'إنها أداة مولد صوت AI TikTok طورتها أحدث تقنية tts TikTok، يمكنها توليد أصوات TikTok مثل صوت جيسي (صوت أنثوي)، صوت سيري، صوت غوستفيس، صوت C3PO (صوت روبوت)، الصوت العميق (راوي)، صوت سكاي، الصوت الدافئ، صوت بيستي، صوت البطل (صوت شيريس)، الصوت التعاطفي، الصوت الجدي، صوت جوي، صوت ستيتش، صوت جندي العاصفة (ستار وورز)، صوت روكيت (حراس المجرة).',
    answer1_2: 'أصوات AI TikTok التي ستدعم قريبًا تشمل: صوت ديريك، صوت المخادع، صوت أوستن بتلر، صوت مراسل الأخبار، صوت آدم، صوت الكلب، صوت مايلي سايرس، صوت أليكسا، صوت الفضائي، صوت الحيوان، صوت الطفل، صوت الكمبيوتر، صوت الشيبمانك، صوت الصدى، صوت npr.',
    answer1_3: 'بالإضافة إلى ذلك، أداة مولد صوت TikTok تدعم العديد من اللغات الأخرى، بما في ذلك الصينية، اليابانية، الكورية، الفيتنامية، التايلاندية، الهندية، الفارسية، الروسية، الألمانية، الفرنسية، الرومانية، التشيكية، الإسبانية، البرتغالية، البنغالية، الإيطالية، العربية، الأردية، الصينية التقليدية والماليزية.',
    answer1_4: 'إذا كنت بحاجة ماسة إلى صوت معين، يرجى مراسلتي عبر البريد الإلكتروني.',

quest2: 'ما هي فوائد مولد صوت TikTok؟',
    answer2_1: '- أداة مولد صوت TikTok يمكنها توليد أنواع مختلفة من الأصوات التي تستخدم غالباً في فيديوهات TikTok.',
    answer2_2: '- إنها أحدث مولد صوت AI يمكنه توليد أصوات tts TikTok تشبه الأصوات البشرية.',
    answer2_3: '- إنها أكثر راحة لتحرير الفيديو على الكمبيوتر الشخصي.',
    answer2_4: '- يمكنك استخدام بعض الأصوات التي لا يمكن العثور عليها حالياً في تطبيق tts TikTok.',

quest3: 'كيف تستخدم مولد صوت TikTok؟',
    answer3_1: 'مولد صوت TikTok سهل جداً في الاستخدام:',
    answer3_2: '- اختر اللغة واللهجة للصوت.',
    answer3_3: '- اكتب النص الذي تريد تحويله إلى صوت في مربع الإدخال.',
    answer3_4: '- اضغط على زر التوليد وانتظر بضع ثوانٍ.',
    answer3_5: '- قم بتشغيل أو تحميل صوت AI TikTok.',

quest4: 'ما هي أشهر وأطرف أصوات TikTok؟',
    answer4_1: 'أشهر صوت ذكوري في TikTok هو الصوت العميق، المعروف رسمياً باسم الراوي.',
    answer4_2: 'لأطرف صوت TikTok، أوصي شخصياً بصوت غوستفيس وصوت C3PO. يتم استخدام هذه الأصوات غالباً لدبلجة الفيديوهات المضحكة في TikTok لأنها سهلة التعرف عليها ويمكنها جذب الجمهور بسرعة.',

quest5: 'ما هي أكثر أصوات TikTok إزعاجاً؟',
    answer5_1: 'أعتقد أنه لا يوجد صوت TikTok "مزعج" حقاً.',
    answer5_2: 'يتعلق الأمر أكثر بالسياق والمحتوى الذي يُستخدم فيه الصوت والذي قد يجعل بعض الناس يشعرون بعدم الراحة. على سبيل المثال، قد يجد البعض صوت جيسي مزعجاً لأنه ظهر بشكل متكرر جداً في TikTok خلال فترة معينة. ومع ذلك، نظراً لنغمته وجودته، فهو في الحقيقة تأثير صوت TikTok رائع. بالإضافة إلى ذلك، الممثلة الصوتية لـ TikTok وراءه محترفة جداً وتنشر أيضاً فيديوهاتها الخاصة على TikTok.',
    answer5_3: 'لذا، أقترح أن يترك الجميع مخاوفهم وتحيزاتهم جانباً، ويستمعوا إلى الأصوات من هذه الأداة عدة مرات، ويجدوا بصبر تأثير صوت TikTok الذي يناسبهم أكثر.',

quest6: 'كيف تستخدم مولد صوت TikTok لتوليد صوت جيسي؟',
    answer6_1: '- في القائمة المنسدلة الأولى من أداة مولد صوت TikTok، اختر English (US)، ثم في القائمة المنسدلة الأخرى، اختر صوت جيسي (صوت أنثوي).',
    answer6_2: '- اكتب النص في مربع الإدخال وانقر على زر التوليد.',
    answer6_3: '- انتظر بضع ثوانٍ أو حتى عشر ثوانٍ، ستسمع صوت AI. في شريط الأدوات، ابحث عن الزر ذو السهم المتجه للأسفل وانقر عليه لتحميله.',

quest7: 'كيف تستخدم مولد صوت TikTok لتوليد صوت C3PO؟',
    answer7_1: '- في القائمة المنسدلة الأولى من أداة مولد صوت TikTok، اختر English (US)، ثم في القائمة المنسدلة الأخرى، اختر صوت C3PO (صوت روبوت).',
    answer7_2: '- اكتب النص في مربع الإدخال وانقر على زر التوليد.',
    answer7_3: '- انتظر بضع ثوانٍ أو حتى عشر ثوانٍ، ستسمع صوت AI. في شريط الأدوات، ابحث عن الزر ذو السهم المتجه للأسفل وانقر عليه لتحميله.',

quest8: 'كيف تستخدم مولد صوت TikTok لتوليد صوت غوستفيس؟',
    answer8_1: '- في القائمة المنسدلة الأولى من أداة مولد صوت TikTok، اختر English (US)، ثم في القائمة المنسدلة الأخرى، اختر صوت غوستفيس.',
    answer8_2: '- اكتب النص في مربع الإدخال وانقر على زر التوليد.',
    answer8_3: '- انتظر بضع ثوانٍ أو حتى عشر ثوانٍ، ستسمع صوت AI. في شريط الأدوات، ابحث عن الزر ذو السهم المتجه للأسفل وانقر عليه لتحميله.',

quest9: 'كيف تستخدم مولد صوت TikTok لتوليد صوت سيري؟',
    answer9_1: '- في القائمة المنسدلة الأولى من أداة مولد صوت TikTok، اختر English (US)، ثم في القائمة المنسدلة الأخرى، اختر صوت سيري.',
    answer9_2: '- اكتب النص في مربع الإدخال وانقر على زر التوليد.',
    answer9_3: '- انتظر بضع ثوانٍ أو حتى عشر ثوانٍ، ستسمع صوت AI. في شريط الأدوات، ابحث عن الزر ذو السهم المتجه للأسفل وانقر عليه لتحميله.',

quest10: 'كيف تضيف تأثير صوت TikTok إلى فيديو TikTok؟',
    answer10_1: 'إذا كنت ترغب في معرفة كيفية استخدام الأصوات الرسمية لـ TikTok على TikTok، سأكتب قريباً مقالاً مخصصاً لهذا الموضوع.',
    answer10_2: 'هنا سأشرح كيفية نشر صوتك على TikTok بعد توليده وتحميله من مولد صوت TikTok.',
    answer10_3: '1. إذا قمت بإنشاء صوت TikTok على جهاز الكمبيوتر الخاص بك وترغب في تحميله على TikTok أو أي تطبيق آخر لتحرير الفيديو على هاتفك، ستحتاج إلى نقل ملف الصوت إلى هاتفك. بالنسبة لأجهزة iPhone، يمكنك استخدام airDrop للنقل. بالنسبة لهواتف Android، لست مألوفاً جداً بهذه العملية، لكنك ستجد بالتأكيد طرقاً وأدوات للقيام بذلك.',
    answer10_4: '2. بمجرد نقل ملف الصوت إلى هاتفك، يمكنك فتح TikTok:',
    answer10_5: '- اضغط على زر "+" في الجزء السفلي من الواجهة، حدد الفيديو في هاتفك.',
    answer10_6: '- بعد تحميل الفيديو، ابحث عن رمز مربع في الجانب الأيمن من الشاشة للدخول إلى صفحة التحرير.',
    answer10_7: '- في الجزء السفلي من الصفحة، ابحث عن زر "إضافة صوت" واضغط عليه لتحديد ملف الصوت الذي تم نقله حديثاً.',
    answer10_8: '- في هذه الواجهة، يمكنك إجراء بعض التعديلات على الفيديو، ثم اضغط على الزر في الزاوية العلوية اليمنى لنشره.',

quest11: 'هل TikTok Voice مجاني؟',
    answer11: 'نعم، إنه أداة مجانية لتوليد صوت AI TikTok من النص إلى الصوت.',

quest12: 'أين يمكنني توليد صوت TikTok شائع في فيديو TikTok الخاص بي؟',
    answer12: 'يمكنك زيارة https://tiktokvoice.net/ لإضافة أصوات AI TikTok إلى الفيديو الخاص بك.'
  },
  pricing: {
    new_features_alert: "📣 لقد أطلقنا 🎙️ استنساخ الصوت و 🤖 خدمات API للأعضاء المدفوعين! (بعد الدفع، يرجى إرسال بريد إلكتروني إلى tiktokaivoicetool@gmail.com للحصول على المساعدة بشأن هذه الميزات). بالإضافة إلى ذلك، لا تتردد في مراسلتنا عبر البريد الإلكتروني لأي طلبات تخصيص😃", // 新增
    pricing_title: 'هناك خطة مناسبة لك، من المبدعين الفرديين إلى الشركات الكبيرة.',
    pricing_desc: 'أكثر من 100 صوت طبيعي وبشري. أكثر من 15 لغة. أقل سعر لأي شخص تقريبًا',
    sub_free_name: 'مجاني',
    sub_free_desc: 'للأفراد الذين يرغبون في تجربة أحدث الصوتيات الذكية',
    sub_free_content: [
      'حد 1000 حرف شهريًا',
      '5 عمليات إنشاء يوميًا',
    ],
    sub_starter_name: 'مبتدئ',
    sub_starter_desc: 'للمبدعين الذين ينشئون محتوى متميزًا لجمهور عالمي',
    sub_starter_content: [
      'حد 1 صوت مستنسخ',
      'حد 300,000 حرف شهريًا (حوالي 7 ساعات من الصوت)',
      'تنزيلات غير محدودة',
      'دعم عبر البريد الإلكتروني خلال 72 ساعة',
      'وصول مبكر إلى الأصوات والميزات الجديدة',
    ],
    sub_pro_name: 'محترف',
    sub_pro_desc: 'للمبدعين الذين يوسعون نطاق إنتاج المحتوى الخاص بهم',
    sub_pro_content: [
      'وصول API',
      'حد 3 أصوات مستنسخة',
      'حد 1,000,000 حرف شهريًا (حوالي 24 ساعة من الصوت)',
      'تنزيلات غير محدودة',
      'دعم عبر البريد الإلكتروني خلال 48 ساعة',
      'وصول مبكر إلى الأصوات والميزات الجديدة',
    ],
    period_year: 'سنويًا',
    period_month: 'شهريًا',
    period_month_short: 'شهر',
    billed_year: 'تُدفع سنويًا',
    most_popular: 'الأكثر شعبية',
    discount_quantity: 'وفر 48 دولارًا',
    buy_btntext: 'ابدأ',
    pay_succ: 'تم الدفع بنجاح!',
    pay_succ_desc: 'شكرًا لك على الشراء. تمت معالجة المعاملة بنجاح.',
    pay_succ_btntext: 'ابدأ باستخدام صوت TikTok',
    pay_fail: 'فشل الدفع!',
    pay_fail_desc: "عذرًا، لم نتمكن من معالجة معاملتك. يرجى المحاولة مرة أخرى أو الاتصال بفريق الدعم لدينا (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'العودة إلى الصفحة الرئيسية',
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}